import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { createDocument, updateDocument } from "./my-document.service";
import { getConvertedFileName } from "app/utils/utility.service";
import { Dropdown } from "primereact/dropdown";
import { convertMBToBytes } from "app/utils/utility.service";

const MyDocument = ({
    documentModal,
    handleDocumentModal,
    sessionValues,
    rowData,
    listOfDocuments,
    allDocumentList
}) => {
    const [documentValues, setDocumentValues] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [currentDocument, setCurrentDocument] = useState(null);

    const fileUploadRef = useRef(null);
    useEffect(() => {
        if (!rowData?.documentName) {
            setDocumentList(allDocumentList.filter(i => !listOfDocuments.includes(i?.name)));
            // let record = [...new Set([...documentList, ...documentList])]
        } else {
            if(!!rowData?.additionalFieldName) {
                const filterRecord = allDocumentList.find((ld)=>ld.id === rowData?.documentlist_id);
                if(filterRecord) {
                    setCurrentDocument(filterRecord);
                } else {
                    setCurrentDocument(null);
                }

            }
            const list = allDocumentList.filter(i => listOfDocuments.includes(i?.name));
            setDocumentList(list);
        }
        setDocumentValues({...rowData, documentName: rowData?.documentlist_id});
    }, [rowData]);

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        if(name === "documentName") {
            const filterRecord = allDocumentList.find((ld)=>ld.id === value);
            if(filterRecord) {
                setCurrentDocument(filterRecord);
            } else {
                setCurrentDocument(null);
            }
            setDocumentValues({...documentValues, "documentName": filterRecord?.name, "documentlist_id": value});
        } else {
            setDocumentValues({ ...documentValues, [name]: value });
        }
    };

    const validateInput = () => {
        if (!documentValues.documentName) {
            return false;
        }
        if (!rowData?.id) {
            if (!documentValues?.files?.name) {
                return false;
            }
        }
        // if (!values?.dateOfBirthImage?.name) {
        //     return false;
        // }
        // if (!values?.addressProofImage?.name) {
        //     return false;
        // }
        // if (!values?.aadharProof?.name) {
        //     return false;
        // }
        return true;
    };

    const handleDocumentSave = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!validateInput()) {
            return false;
        }
        setIsLoading(true);
        let formData = new FormData();

        if (!!documentValues.files) {
            const convertedFileName = getConvertedFileName(
                documentValues?.fileName
            );
            formData.append("fileName", `${convertedFileName}`);
            formData.append(
                "file",
                documentValues?.files,
                `${convertedFileName}`
            );
            delete documentValues?.files;
            delete documentValues?.fileName;
        }
        for (const key of Object.keys(documentValues)) {
            formData.append(key, `${documentValues[key]}`);
        }
        if (!!rowData?.id) {
            const record = await updateDocument(documentValues?.id, formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
            } else {
                setIsLoading(false);
                alert("Record updated successfully");
                // insert settings value
                handleDocumentModal();
            }
        } else {
            formData.append("user_id", sessionValues.id);
            const record = await createDocument(formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
            } else {
                setIsLoading(false);
                alert("Record created successfully");
                // insert settings value
                handleDocumentModal();
            }
        }
    };

    const myUploader = (event, name) => {
        const size = convertMBToBytes(Number(rowData?.fileSize));
        if (size <= event.files[0].size) {
            alert(
                "You cannot upload file size more than " +
                    rowData?.fileSize +
                    "MB"
            );
            fileUploadRef?.current.clear();
            return;
        }
        setDocumentValues({
            ...documentValues,
            [name]: event.files[0],
            [`fileName`]: event.files[0].name,
        });
    };

    const onHide = () => {
        handleDocumentModal(false);
    };
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className="col-7">
                        {/* <Button
                            label="Cancel"
                            className="p-button-raised p-button-lg"
                            onClick={() => onHide()}
                        /> */}
                        <Button
                            label="Save"
                            className="p-button-raised p-button-warning p-button-lg"
                            onClick={handleDocumentSave}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const enterRequiredField = () => (
        <div className="p-error text-left">Please enter required field.</div>
    );


    return (
        <Dialog
            header={!!rowData?.id ? "Update Document" : "Add Document"}
            visible={documentModal}
            onHide={() => onHide()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw" }}
            footer={renderFooter()}
            className="AnnualExpenseModal"
            position="top"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="text-right mt-4">
                {/* Examination Passed */}
                {isLoading && <Loading />}

                

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Select Document * </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            {" "}
                            <div>
                                <span className="p-float-label">
                                    <Dropdown
                                        className="vw-dropdown"
                                        value={documentValues?.documentlist_id}
                                        options={documentList}
                                        required={true}
                                        name="documentName"
                                        onChange={handleModalInputChange}
                                        optionLabel="name"
                                        optionValue="id"
                                        // placeholder="Select a Document"
                                    />
                                    <label>Select a Document *</label>
                                </span>
                                {formSubmitted &&
                                    !documentValues?.documentName &&
                                    enterRequiredField()}
                            </div>
                        </div>
                    </div>
                </div>
                
                {!!currentDocument && !!currentDocument?.additionalFieldName && (
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">{currentDocument?.additionalFieldName}* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputText
                                autoFocus
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="additionalFieldName"
                                value={documentValues?.additionalFieldName}
                                onChange={handleModalInputChange}
                            />
                            {currentDocument?.isManadatory === "Yes" && formSubmitted &&
                                !documentValues?.additionalFieldName && (
                                    enterRequiredField()
                            )}
                        </div>
                    </div>
                </div>
                )}

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Upload Document* </label>
                    </div>
                    <div className="col-5">
                        <div className="field text-left">
                            <FileUpload
                                ref={fileUploadRef}
                                name="profileImage"
                                className="fileUpload"
                                mode="basic"
                                customUpload
                                uploadHandler={(event) =>
                                    myUploader(event, "files")
                                }
                                // onUpload={(event) =>
                                //     myUploader(event, "profileImage")
                                // }
                                auto
                                chooseLabel="Choose PDF Document"
                                accept="pdf/*"
                            />
                            {formSubmitted &&
                                !documentValues?.files?.name &&
                                enterRequiredField()}
                            <label className="ml-4 mt-2">
                                <strong>{documentValues?.fileName}</strong>{" "}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default MyDocument;
