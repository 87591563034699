import { Loading } from "app/components";
import moment from "moment";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { bodyTemplate } from "app/utils/datatable.service";
import { dateTemplate } from "app/utils/datatable.service";
import { Message } from "primereact/message";
import { Link } from "react-router-dom";
import { deleteExperience, getExperienceList } from "./my-experience.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { pdfTemplate } from "app/utils/datatable.service";
import MyExperience from "./MyExperience";

const MyExperienceList = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [experienceModal, setExperienceModal] = useState(false);
    const [list, setList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const sessionValues = getSessionUserInformation();

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete?")) {
            const deletedRecord = await deleteExperience(id);
            if (deletedRecord) {
                fetchExperienceList();
                alert("Record deleted successfully");
                return;
            } else {
                alert("Something went wrong, please try again later");
                return;
            }
        }
    };

    const handleEdit = async (data) => {
        setRowData(data);
        handleExperienceModal();
    };

    const actionBodyTemplate = (data, props) => {
        return (
            <>
                <Button
                    onClick={() => handleEdit(data)}
                    className="p-button p-button-primary mr-2"
                >
                    Edit
                </Button>
                <Button
                    onClick={() => handleDelete(data?.id)}
                    className="p-button p-button-danger"
                >
                    Delete
                </Button>
            </>
        );
    };

    const fetchExperienceList = async () => {
        setIsLoading(true);

        let list = await getExperienceList(sessionValues?.id);
        setIsLoading(false);
        if (list.length > 0) {
            list.map((li, index) => (li.srNo = index + 1));
            setList(list);
        } else {
            setList([]);
        }
        return;
    };

    const handleExperienceModal = () => {
        if (experienceModal) {
            fetchExperienceList();
            setRowData(null);
        }
        setExperienceModal(!experienceModal);
    };

    useEffect(() => {
        fetchExperienceList();
    }, []);

    const renderInputQualificationModal = () => (
        <MyExperience
            experienceModal={experienceModal}
            handleExperienceModal={handleExperienceModal}
            sessionValues={sessionValues}
            rowData={rowData}
        />
    );

    return (
        <Fragment>
            <div className="card">
                <Card className="mb-4" title="My Experiences">
                    <div className="grid table-demo">
                        <Toast ref={toast} className="ToastMessage" />
                        {isLoading && (
                            <div className="spinner inner-spinner">
                                <Loading />
                            </div>
                        )}
                        <div className="col-12 lg:col-12 md:col-12">
                            <div className="card">
                                <Button
                                    onClick={handleExperienceModal}
                                    label="Add New"
                                    className="p-button-raised p-button-warning mb-3"
                                />
                                <DataTable
                                    value={list}
                                    scrollable
                                    scrollHeight="600px"
                                    scrollDirection="both"
                                    className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    rows={10}
                                    dataKey="id"
                                    paginator
                                    rowHover
                                    // globalFilter={globalFilter}
                                    emptyMessage="No list found."
                                    loading={isLoading}
                                    // header={tableHeader}
                                >
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "50px",
                                        }}
                                        field="srNo"
                                        header="Sr. No."
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="name_of_organization"
                                        header="Name of Organization"
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="post"
                                        header="Post"
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="start_date"
                                        header="Start Date"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="end_date"
                                        header="End Date"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="pay_scale"
                                        header="Pay Scale"
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="fileName"
                                        header="File Name"
                                        sortable
                                        body={(data) =>
                                            pdfTemplate(
                                                data,
                                                "File Name",
                                                "fetchExperiencesPDF"
                                            )
                                        }
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="status"
                                        header="Action"
                                        body={actionBodyTemplate}
                                    ></Column>
                                    {/* <Column
                                style={{ flexGrow: 1, flexBasis: "50px" }}
                                field=""
                                header="Actions"
                                body={actionBodyTemplate}
                            ></Column> */}
                                </DataTable>
                            </div>
                        </div>
                        {!!experienceModal && renderInputQualificationModal()}
                        {/* {displayConfirmation && (
                <ConfirmationDialogBox
                    handleCancelConfirmation={handleCancelConfirmation}
                    handleConfirmation={handleConfirmation}
                    displayConfirmation={displayConfirmation}
                />
            )} */}
                    </div>
                </Card>
            </div>
        </Fragment>
    );
};

export default MyExperienceList;
