import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { createExperience, updateExperience } from "./my-experience.service";
import { getConvertedFileName } from "app/utils/utility.service";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from 'primereact/inputtextarea';
import moment from "moment";


const MyExperience = ({
    experienceModal,
    handleExperienceModal,
    sessionValues,
    rowData,
}) => {
    const [values, setValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        setValues(rowData);
    }, [rowData]);

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const validateInput = () => {
        if (
            !values.name_of_organization ||
            !values.start_date ||
            !values.end_date ||
            !values.nature_of_duties ||
            !values.post ||
            !values.pay_scale
        ) {
            return false;
        }
        if (!rowData?.id) {
            if (!values?.files?.name) {
                return false;
            }
        }
        if(values?.start_date && values?.end_date) {
            if(moment(values?.start_date).isAfter(moment(values?.end_date))) {
                alert("Start Date cannot be greater than End Date");
                return false;
            }
        }
        // if (!values?.dateOfBirthImage?.name) {
        //     return false;
        // }
        // if (!values?.addressProofImage?.name) {
        //     return false;
        // }
        // if (!values?.aadharProof?.name) {
        //     return false;
        // }
        return true;
    };

    const handleSave = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!validateInput()) {
            return false;
        }
        setIsLoading(true);
        let formData = new FormData();

        if (!!values.files) {
            const convertedFileName = getConvertedFileName(values?.fileName);
            formData.append("fileName", `${convertedFileName}`);
            formData.append("file", values?.files, `${convertedFileName}`);
            delete values?.files;
            delete values?.fileName;
        }
        for (const key of Object.keys(values)) {
            formData.append(key, `${values[key]}`);
        }
        if (!!rowData?.id) {
            const record = await updateExperience(values?.id, formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
            } else {
                setIsLoading(false);
                alert("Record updated successfully");

                setFormSubmitted(false);
                // insert settings value
                handleExperienceModal();
            }
        } else {
            formData.append("user_id", sessionValues.id);
            const record = await createExperience(formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
                setFormSubmitted(false);
            } else {
                setIsLoading(false);
                alert("Record created successfully");
                // insert settings value
                handleExperienceModal();
            }
        }
    };

    const myUploader = (event, name) => {
        setValues({
            ...values,
            [name]: event.files[0],
            [`fileName`]: event.files[0].name,
        });
    };

    const onHide = () => {
        handleExperienceModal(false);
    };
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className="col-7">
                        {/* <Button
                            label="Cancel"
                            className="p-button-raised p-button-lg p-button-orange"
                            onClick={() => onHide()}
                        /> */}
                        <Button
                            label="Save"
                            className="p-button-raised p-button-warning p-button-lg"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const enterRequiredField = () => (
        <div className="p-error text-left">Please enter required field.</div>
    );

    return (
        <Dialog
            header={!!rowData?.id ? "Update Experience" : "Add Experience"}
            visible={experienceModal}
            onHide={() => onHide()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw" }}
            footer={renderFooter()}
            className="AnnualExpenseModal"
            position="top"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="text-right mt-4">
                {/* Examination Passed */}
                {isLoading && <Loading />}
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Organization Name* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputText
                                autoFocus
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="name_of_organization"
                                value={values?.name_of_organization}
                                onChange={handleModalInputChange}
                            />
                            {formSubmitted &&
                                !values?.name_of_organization &&
                                enterRequiredField()}
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Post* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="post"
                                value={values?.post}
                                onChange={handleModalInputChange}
                            />
                            {formSubmitted &&
                                !values?.post &&
                                enterRequiredField()}
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Start Date* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <Calendar
                                style={{ width: "100%" }}
                                inputId="start_date"
                                value={new Date(values?.start_date)}
                                name="start_date"
                                onChange={handleModalInputChange}
                                dateFormat="dd-mm-yy"
                                showIcon
                                monthNavigator
                                yearNavigator 
                                yearRange={`1970:${moment().format("YYYY")}`}
                            />
                            {formSubmitted &&
                                !values?.start_date &&
                                enterRequiredField()}
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">End Date* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <Calendar
                                style={{ width: "100%" }}
                                inputId="end_date"
                                value={new Date(values?.end_date)}
                                name="end_date"
                                onChange={handleModalInputChange}
                                dateFormat="dd-mm-yy"
                                showIcon
                            />
                            {formSubmitted &&
                                !values?.end_date &&
                                enterRequiredField()}
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Pay Scale* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="pay_scale"
                                value={values?.pay_scale}
                                onChange={handleModalInputChange}
                            />
                            {formSubmitted &&
                                !values?.pay_scale &&
                                enterRequiredField()}
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Nature Of Duties* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputTextarea
                                className="vw-input-nfl"
                                autoComplete="off"
                                autoResize
                                required={true}
                                name="nature_of_duties"
                                value={values?.nature_of_duties}
                                onChange={handleModalInputChange}
                                rows={5} 
                                cols={30}
                            />
                            {formSubmitted &&
                                !values?.nature_of_duties &&
                                enterRequiredField()}
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Upload Document* </label>
                    </div>
                    <div className="col-5">
                        <div className="field text-left">
                            <FileUpload
                                name="profileImage"
                                className="fileUpload"
                                mode="basic"
                                customUpload
                                uploadHandler={(event) =>
                                    myUploader(event, "files")
                                }
                                // onUpload={(event) =>
                                //     myUploader(event, "profileImage")
                                // }
                                auto
                                chooseLabel="Choose PDF Document"
                                accept="pdf/*"
                            />
                            {formSubmitted &&
                                !values?.files?.name &&
                                enterRequiredField()}
                            <label className="ml-4 mt-2">
                                <strong>{values?.fileName}</strong>
                            * </label>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default MyExperience;
