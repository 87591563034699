import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import {
    createQualification,
    updateQualifcation,
} from "./my-qualification.service";
import { getConvertedFileName } from "app/utils/utility.service";

const MyQualification = ({
    qualificationModal,
    handleQualifcationModal,
    sessionValues,
    rowData,
}) => {
    const [qualificationValues, setQualificationValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        setQualificationValues(rowData);
    }, [rowData]);

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setQualificationValues({ ...qualificationValues, [name]: value });
    };

    const validateInput = () => {
        if (
            !qualificationValues.examination_passed ||
            !qualificationValues.year_of_passing ||
            !qualificationValues.university ||
            !qualificationValues.percentage
        ) {
            return false;
        }
        if (!rowData?.id) {
            if (!qualificationValues?.files?.name) {
                return false;
            }
        }
        // if (!values?.dateOfBirthImage?.name) {
        //     return false;
        // }
        // if (!values?.addressProofImage?.name) {
        //     return false;
        // }
        // if (!values?.aadharProof?.name) {
        //     return false;
        // }
        return true;
    };

    const handleQualificationSave = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!validateInput()) {
            return false;
        }
        setIsLoading(true);
        let formData = new FormData();

        if (!!qualificationValues.files) {
            const convertedFileName = getConvertedFileName(
                qualificationValues?.fileName
            );
            formData.append("fileName", `${convertedFileName}`);
            formData.append(
                "file",
                qualificationValues?.files,
                `${convertedFileName}`
            );
            delete qualificationValues?.files;
            delete qualificationValues?.fileName;
        }
        for (const key of Object.keys(qualificationValues)) {
            formData.append(key, `${qualificationValues[key]}`);
        }
        if (!!rowData?.id) {
            const record = await updateQualifcation(
                qualificationValues?.id,
                formData
            );
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
            } else {
                setIsLoading(false);
                alert("Record updated successfully");
                // insert settings value
                handleQualifcationModal();
            }
        } else {
            formData.append("user_id", sessionValues.id);
            const record = await createQualification(formData);
            if (record.status === false) {
                setIsLoading(false);
                alert(record?.message);
            } else {
                setIsLoading(false);
                alert("Record created successfully");
                // insert settings value
                handleQualifcationModal();
            }
        }
    };

    const myUploader = (event, name) => {
        setQualificationValues({
            ...qualificationValues,
            [name]: event.files[0],
            [`fileName`]: event.files[0].name,
        });
    };

    const onHide = () => {
        handleQualifcationModal(false);
    };
    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    <div className="col-3"></div>
                    <div className="col-7">
                        {/* <Button
                            label="Cancel"
                            className="p-button-raised p-button-lg"
                            onClick={() => onHide()}
                        /> */}
                        <Button
                            label="Save"
                            className="p-button-raised p-button-warning p-button-lg"
                            onClick={handleQualificationSave}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const enterRequiredField = () => (
        <div className="p-error text-left">Please enter required field.</div>
    );

    return (
        <Dialog
            header={
                !!rowData?.id ? "Update Qualification" : "Add Qualification"
            }
            visible={qualificationModal}
            onHide={() => onHide()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw" }}
            footer={renderFooter()}
            className="AnnualExpenseModal"
            position="top"
            closeOnEscape={false}
            draggable={false}
        >
            <div className="text-right mt-4">
                {/* Examination Passed */}
                {isLoading && <Loading />}
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Examination Passed* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputText
                                autoFocus
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="examination_passed"
                                value={qualificationValues?.examination_passed}
                                onChange={handleModalInputChange}
                            />
                            {formSubmitted &&
                                !qualificationValues?.examination_passed && (
                                    enterRequiredField()
                                )}
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">University* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="university"
                                value={qualificationValues?.university}
                                onChange={handleModalInputChange}
                            />
                            {formSubmitted &&
                                !qualificationValues?.university && (
                                    enterRequiredField()
                                )}
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Year of Passing* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputNumber
                                className="vw-inputnumber-with-border"
                                autoComplete="off"
                                required={true}
                                name="year_of_passing"
                                useGrouping={false}
                                disabled={false}
                                style={{ width: "100%" }}
                                value={qualificationValues?.year_of_passing}
                                onValueChange={handleModalInputChange}
                            />
                            {formSubmitted &&
                                !qualificationValues?.year_of_passing && (
                                    enterRequiredField()
                                )}
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">CGPA / Percentage* </label>
                    </div>
                    <div className="col-5">
                        <div className="field">
                            <InputNumber
                                className="vw-inputnumber-with-border w-100"
                                autoComplete="off"
                                required={true}
                                useGrouping={false}
                                name="percentage"
                                min={1}
                                max={99.99}
                                disabled={false}
                                maxFractionDigits={2}
                                onValueChange={handleModalInputChange}
                                value={qualificationValues?.percentage}
                            />
                            {formSubmitted &&
                                !qualificationValues?.percentage && (
                                    enterRequiredField()
                                )}
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-3">
                        <label className="mt-3">Upload Document* </label>
                    </div>
                    <div className="col-5">
                        <div className="field text-left">
                            <FileUpload
                                name="profileImage"
                                className="fileUpload"
                                mode="basic"
                                customUpload
                                uploadHandler={(event) =>
                                    myUploader(event, "files")
                                }
                                // onUpload={(event) =>
                                //     myUploader(event, "profileImage")
                                // }
                                auto
                                chooseLabel="Choose PDF Document"
                                accept="pdf/*"
                            />
                            {formSubmitted &&
                                !qualificationValues?.files?.name && (
                                    enterRequiredField()
                                )}
                            <label className="ml-4 mt-2">
                                <strong>{qualificationValues?.fileName}</strong>
                            * </label>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default MyQualification;
