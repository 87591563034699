import { getSessionAgentInformation } from "app/utils/utility.service";
import { getSessionBrokerInformation } from "app/utils/utility.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { clearHomePurchaseValues } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Menu } from "primereact/menu";
import { Fragment, useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Partials.scss";
import { Card } from "primereact/card";
import { ProgressBar } from 'primereact/progressbar';
import { Avatar } from 'primereact/avatar';
import { getProfileCompletionPercentage } from "app/utils/utility.service";
import { getSingleUser } from "app/views/sessions/my-profile/my-profile.service";

const Header = ({handleSidebar}) => {
    const menu = useRef(null);
    const sessionValues = getSessionUserInformation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userInformation, setUserInformation] = useState(null);

    const fetchUserInfo = async () => {
        // const userInfo = await
        const userInfo = await getSingleUser(sessionValues?.id);
        if (userInfo) {
            setUserInformation(userInfo);
            return;
        }
        setUserInformation(null);
    };

    useEffect(() => {
        if(!!sessionValues?.id) {
            fetchUserInfo()
        }
    }, [sessionValues?.id])

    let items = [
        {
            label: "Home",
            command: () => {
                window.location.hash = `/`;
            },
        },
        {
            label: "My Profile",
            command: () => {
                window.location.hash = `/my-profile`;
            },
        },
        {
            label: "My Documents",
            command: () => {
                window.location.hash = `/my-documents`;
            },
        },
        {
            label: "My Qualifcation",
            command: () => {
                window.location.hash = `/my-qualification`;
            },
        },
        {
            label: "My Experience",
            command: () => {
                window.location.hash = `/my-experience`;
            },
        },
        {
            label: "My Applications",
            command: () => {
                window.location.hash = `/my-applications`;
            },
        },
        {
            label: "Logout",
            command: () => {
                dispatch(clearHomePurchaseValues());
                sessionStorage.clear();
                navigate("/");
            },
        },
    ];

   
    const valueTemplate = (value) => {
        return (
            <Fragment>
                <span style={{color:"#fff"}}><b>{value}% Profile Completed</b></span>
            </Fragment>
        );
    };


    return (
        <div className="container-fluid">

            <div className="card">
                <Card className="header-card">
                    <div className="Header">

                        <div className="left">
                            <div>
                                <img
                                    src="/ninlogo.png"
                                    alt="National Institute of Naturopathy, Ministry of Ayush, Govt. of India"
                                    title="National Institute of Naturopathy, Ministry of Ayush, Govt. of India"
                                />
                            </div>
                            <div className="logo_title">
                                <h1 className="">
                                    National Institute of Naturopathy
                                </h1>
                                <h4 className="">
                                    <b>Ministry of Ayush, Govt. of India</b>
                                </h4>
                            </div>
                        </div>
                        {!!sessionValues?.firstname ? (
                            <Fragment>
                                <div className="right">

                                    <h4 className="mr-3"><Link to={`/`}>Home</Link></h4>
                                    <h4>Hi {sessionValues?.firstname}</h4>
                                    <Avatar icon="pi pi-user" className="mr-4 ml-4" size="xlarge" style={{ backgroundColor: '#2196F3', color: '#ffffff' }}  shape="circle"  onClick={(event) =>
                                            menu.current.toggle(event)
                                        } />

                                    {/* <h4><i className="pi pi-bars" onClick={handleSidebar} style={{ fontSize: '2rem', cursor:"pointer" }}></i></h4> */}

                                    <Menu
                                        model={items}
                                        popup
                                        ref={menu}
                                        id="popup_menu"
                                    />
                                    {/* <i
                                        className="pi pi-bars ml-2"
                                        style={{
                                            fontSize: "1em",
                                            cursor: "pointer",
                                        }}
                                       
                                        aria-controls="popup_menu"
                                        aria-haspopup
                                    ></i> */}
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="right">
                                    <div class="mr-5">
                                        <i>
                                            <h5 class="quote">
                                                Those who do not find time every
                                                day for health must sacrifice a
                                                lot of time one day for illness.{" "}
                                            </h5>
                                            <h5 class="naturopath">
                                                - Father Sebastian Kneipp (1821
                                                - 1894)
                                            </h5>
                                        </i>
                                    </div>
                                    <div className="ml-5">
                                        <img
                                            src="/gandhi2.png"
                                            alt="राष्ट्रीय प्राकृतिक चिकित्सा संस्थान, आयुष मंत्रालय, भारत सरकार"
                                            title="राष्ट्रीय प्राकृतिक चिकित्सा संस्थान, आयुष मंत्रालय, भारत सरकार"
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Card>
                {!!userInformation?.firstname && (
                    <div className="mt-5">
                    <ProgressBar value={getProfileCompletionPercentage(userInformation)} displayValueTemplate={valueTemplate} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
