import { getSessionUserInformation } from "app/utils/utility.service";
import { getSessionClientInformation } from "app/utils/utility.service";
import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const updateRentDownPayment = async (payload, userId) => {
    const result = await restClient.put(`${BASE_URL}/users/${userId}`,payload);
    return result;
}


export const insertUpdateMonthlyExpenses = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/monthlyexpenses/create`,payload);
    return result?.data;
}

export const getMonthlyExpenses = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/monthlyexpenses/${payload.userId}`,[]);
    return result?.data; 
}


export const getIncomeCalculations = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/incomecalculations/${payload.userId}`,[]);
    return result?.data; 
}

export const getMortgageStructureDebts = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/mortgagedebts/${payload.userId}`,[]);
    return result?.data; 
}

export const getMortgageStructureDirectIncome = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/mortgagedirectincomes/${payload.userId}`,[]);
    return result?.data; 
}
export const getMortgageStructureIndirectIncome = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/mortgageindirectincomes/${payload.userId}`,[]);
    return result?.data; 
}

export const insertUpdateIncomeCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/incomecalculations/create`,payload);
    return result?.data;
}

export const insertUpdateMortgageDebtCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/mortgagedebts/create`,payload);
    return result?.data;
}
export const insertUpdateMortgageDirectIncomeCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/mortgagedirectincomes/create`,payload);
    return result?.data;
}
export const insertUpdateMortgageIndirectIncomeCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/mortgageindirectincomes/create`,payload);
    return result?.data;
}

export const insertSettings = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/settings/create`,payload);
    return result;
}

export const updateSettings = async (payload) => {
    const result = await restClient.put(`${BASE_URL}/settings/${payload.userId}`,payload);
    return result;
}

export const insertRentalIncome = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/rentalincomes/create`,payload);
    return result?.data;
}

export const updateRentalIncome = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/rentalincomes/${id}`,payload);
    return result?.data;
}

export const getRentalList = async (payload) =>{
    const result = await restClient.get(`${BASE_URL}/rentalincomes/${payload.userId}`,[]);
    return result?.data; 
}

export const deleteRentalRecord = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/rentalincomes/${id}`);
    return result?.data; 
}

export const getClientSetting = async(payload) => {
    const result = await restClient.get(`${BASE_URL}/settings/${payload}`,[]);
    return result?.data; 
}


export const getClientUser = async(payload) => {
    const result = await restClient.get(`${BASE_URL}/users/${payload}`,[]);
    return result?.data; 
}

export const insertLogs = async (payload) => {
    const sessionValues = getSessionUserInformation();
    const clientSessionValues = getSessionClientInformation();
    if(!!clientSessionValues?.id) {
        payload.userId = clientSessionValues?.id;
    } else {
        payload.userId = sessionValues?.id;
    }
    payload.updatedBy = sessionValues.id;
    payload.brokerId = sessionValues?.brokerId ? sessionValues?.brokerId : null;
    payload.agentId = sessionValues?.agentId ? sessionValues?.agentId : null;
    const result = await restClient.post(`${BASE_URL}/logs/create`,payload);
    return result?.data;
}