import { Loading } from "app/components";
import moment from "moment";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { bodyTemplate } from "app/utils/datatable.service";
import { dateTemplate } from "app/utils/datatable.service";
import {
    deleteApplication,
    getApplicationList,
} from "./my-application.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { pdfTemplate } from "app/utils/datatable.service";

const MyApplicationList = () => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const sessionValues = getSessionUserInformation();

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete?")) {
            const deletedRecord = await deleteApplication(id);
            if (deletedRecord) {
                fetchList();
                alert("Record deleted successfully");
                return;
            } else {
                alert("Something went wrong, please try again later");
                return;
            }
        }
    };

    const handleEdit = async (data) => {
        setRowData(data);
    };

    const actionBodyTemplate = (data, props) => {
        return (
            <>
                <Button
                    onClick={() => handleEdit(data)}
                    className="p-button p-button-primary mr-2"
                >
                    Edit
                </Button>
                <Button
                    onClick={() => handleDelete(data?.id)}
                    className="p-button p-button-danger"
                >
                    Delete
                </Button>
            </>
        );
    };

    const fetchList = async () => {
        setIsLoading(true);

        let list = await getApplicationList(sessionValues?.id);
        setIsLoading(false);
        if (list.length > 0) {
            list.reverse();
            list.map((li, index) => (li.srNo = index + 1));
            setList(list);
        } else {
            setList([]);
        }
        return;
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <Fragment>
            <div className="card">
                <Card className="mb-4" title="Applied Vacancies">
                    <div className="grid table-demo">
                        <Toast ref={toast} className="ToastMessage" />
                        {isLoading && (
                            <div className="spinner inner-spinner">
                                <Loading />
                            </div>
                        )}
                        <div className="col-12 lg:col-12 md:col-12">
                            <div className="card">
                                <DataTable
                                    value={list}
                                    scrollable
                                    scrollHeight="600px"
                                    scrollDirection="both"
                                    className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    rows={10}
                                    dataKey="id"
                                    paginator
                                    rowHover
                                    // globalFilter={globalFilter}
                                    emptyMessage="No list found."
                                    loading={isLoading}
                                    // header={tableHeader}
                                >
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "50px",
                                        }}
                                        field="srNo"
                                        header="Sr. No."
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="vacancy.department_name"
                                        header="Department Name"
                                        sortable
                                        // body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="vacancy.vacancy_name"
                                        header="Vacancy name"
                                        sortable
                                        // body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="vacancy.nature"
                                        header="Nature"
                                        sortable
                                        // body={vacancyBodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="applied_on"
                                        header="Applied on"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="form_submitted_on"
                                        header="Form Submitted on"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="fees_paid_on"
                                        header="Fees Paid on"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="status"
                                        header="Status"
                                        body={bodyTemplate}
                                    ></Column>
                                    {/* <Column
                                style={{ flexGrow: 1, flexBasis: "50px" }}
                                field=""
                                header="Actions"
                                body={actionBodyTemplate}
                            ></Column> */}
                                </DataTable>
                            </div>
                        </div>
                        {/* {displayConfirmation && (
                <ConfirmationDialogBox
                    handleCancelConfirmation={handleCancelConfirmation}
                    handleConfirmation={handleConfirmation}
                    displayConfirmation={displayConfirmation}
                />
            )} */}
                    </div>
                </Card>
            </div>
        </Fragment>
    );
};

export default MyApplicationList;
